import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getSDK, getZyfiData } from '@ui/utils';
import type { Fraction } from 'bi-fraction';
import { BrowserProvider } from 'zksync-ethers';

import { REFERRAL_CODE } from '../data/constants';
import { type UseAllPoolsData } from '../data/useAllPoolsData';
import { approveIfNeeded, checkZKCROAddress } from '../data/utils';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useSupplyMutation = () => {
  const account = useAccount();
  const chainId = useChainId();
  const provider = useProvider();
  const connectorProvider = getConnector().provider;
  const [gasTokenSymbol, gasTokenAddress] = usePaymaster((s) => [
    s.token,
    s.tokenAddress,
  ]);
  return useMutation(
    async ({
      amount,
      tokenData,
    }: {
      amount: Fraction;
      tokenData: UseAllPoolsData;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const sdk = getSDK();
      const tokenAddress = checkZKCROAddress(tokenData.tokenAddress);
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      const token = sdk.getERC20(tokenAddress!, browserSigner);
      const decimals = await token.decimals();

      // Native currency does not need approval
      if (tokenData.symbol !== tokenMap.zkCRO) {
        await approveIfNeeded({
          account,
          spender: poolAddress,
          amount: amount.shl(Number(decimals)).quotient,
          token,
          gasTokenAddress,
          gasTokenSymbol,
        });
      }
      // supply directly without using paymaster
      if (gasTokenSymbol === tokenMap.zkCRO) {
        let tx;
        if (tokenData.symbol === tokenMap.zkCRO) {
          tx = await sdk
            .getWrappedTokenGatewayV3(browserSigner)
            .depositETH(poolAddress, account, REFERRAL_CODE, {
              value: amount.shl(Number(decimals)).quotient,
            });
        } else {
          tx = await sdk
            .getL2Pool(poolAddress, browserSigner)
            ['supply(address,uint256,address,uint16)'](
              tokenAddress!,
              amount.shl(Number(decimals)).quotient,
              account,
              REFERRAL_CODE,
            );
        }
        return await tx.wait();
      }

      // zyfi paymaster
      if (tokenData.symbol === tokenMap.zkCRO) {
        const wrappedTokenGateway = sdk.getWrappedTokenGatewayV3(browserSigner);
        const gasLimit = await wrappedTokenGateway.depositETH.estimateGas(
          poolAddress,
          account,
          REFERRAL_CODE,
          {
            value: amount.shl(Number(decimals)).quotient,
          },
        );
        const functionData = wrappedTokenGateway.interface.encodeFunctionData(
          'depositETH',
          [poolAddress, account, REFERRAL_CODE],
        );
        const data = await getZyfiData({
          gasLimit,
          fromAddress: account,
          toAddress: await wrappedTokenGateway.getAddress(),
          gasTokenAddress,
          functionData,
          value: amount.shl(Number(decimals)).quotient,
        });
        const tx = await browserSigner.sendTransaction(data.txData);
        return await tx.wait();
      } else {
        const l2Pool = sdk.getL2Pool(poolAddress, browserSigner);
        const gasLimit = await l2Pool[
          'supply(address,uint256,address,uint16)'
        ].estimateGas(
          tokenAddress!,
          amount.shl(Number(decimals)).quotient,
          account,
          REFERRAL_CODE,
        );
        const functionData = l2Pool.interface.encodeFunctionData(
          'supply(address,uint256,address,uint16)',
          [
            tokenAddress!,
            amount.shl(Number(decimals)).quotient,
            account,
            REFERRAL_CODE,
          ],
        );
        const data = await getZyfiData({
          gasLimit,
          fromAddress: account,
          toAddress: await l2Pool.getAddress(),
          gasTokenAddress,
          functionData,
        });
        const tx = await browserSigner.sendTransaction(data.txData);
        return await tx.wait();
      }
    },
  );
};
