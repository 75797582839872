import { getContractAddress } from '@amply-app/sdk';
import { currentWallet } from '@amply-app/wallet';
import type { UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK, getZyfiData } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { find, isNumber } from 'lodash-es';
import { BrowserProvider } from 'zksync-ethers';

import type { LockingPeriod } from './constants';
import { SECONDS_PER_YEAR, secondsToPeriod } from './constants';
import { approveIfNeeded } from './utils';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export interface StakePool {
  poolId: number;
  poolSeconds: number;
  multiplier: Fraction;
  lockPeriod: LockingPeriod;
  apr: Fraction;
  totalStaked: Fraction;
}

export const useStakingPools = () => {
  const sdk = getSDK();
  const { data: totalStaked } = useVaultTotalWeightedStaked();
  const calculateAPY = (
    emissionPerSecond: Fraction,
    multiplier: Fraction,
    totalStaked?: Fraction,
  ) => {
    if (totalStaked?.gt(0)) {
      return new Fraction(emissionPerSecond)
        .mul(SECONDS_PER_YEAR)
        .mul(multiplier)
        .div(totalStaked);
    }
    return Fraction.ZERO;
  };

  return useQuery(
    ['useStakingPools', totalStaked],
    async () => {
      const poolCounts = await sdk.getAmplyVault().poolLength();
      const emissionPerSecond = await sdk.getAmplyVault().emissionPerSecond();
      const pools = [];
      for (let i = 0; i < Number(poolCounts); i++) {
        const rawPoolInfo = await sdk.getAmplyVault().poolInfo(i);
        pools.push({
          poolId: i,
          poolSeconds: Number(rawPoolInfo.lockPeriod),
          multiplier: new Fraction(rawPoolInfo.multiplier),
          lockPeriod: secondsToPeriod[Number(rawPoolInfo.lockPeriod)],
          apr: calculateAPY(
            new Fraction(emissionPerSecond).shr(18),
            new Fraction(rawPoolInfo.multiplier),
            totalStaked,
          ),
          totalStaked: new Fraction(rawPoolInfo.totalStaked).shr(18),
        });
      }
      return pools;
    },

    {
      refetchInterval: RefetchInterval.Normal,
    },
  );
};

export interface UserStake {
  stakeId: number;
  amount: Fraction;
  lockStartDate: Date;
  lockEndDate: Date;
  pool: StakePool;
}

export const useUserStakingPools = (): UseQueryResult<UserStake[]> => {
  const sdk = getSDK();
  const account = currentWallet.useAccount();
  const { data: pools } = useStakingPools();
  return useQuery(
    ['useUserStakingPools', account, pools],
    async () => {
      const userInfo = await sdk.getAmplyVault().getUserInfo(account!);
      const userStakes = [];
      let ptr = -1;
      for (const stake of userInfo[2]) {
        ptr++;
        const { amount, poolId, stakeTimestamp, unlockTimestamp, active } =
          stake;
        if (!active) {
          continue;
        }
        const pool = find(pools, { poolId: Number(poolId) })!;
        userStakes.push({
          stakeId: ptr,
          pool,
          amount: new Fraction(amount).shr(18),
          lockStartDate: new Date(Number(stakeTimestamp) * 1000),
          lockEndDate: new Date(Number(unlockTimestamp) * 1000),
        });
      }

      return userStakes;
    },
    {
      enabled: !!account,
      refetchInterval: RefetchInterval.Normal,
    },
  );
};

export const useVaultTotalWeightedStaked = () => {
  const sdk = getSDK();
  return useQuery(
    ['useVaultTotalWeightedStaked'],
    async () => {
      const totalStaked = await sdk.getAmplyVault().totalSupply();
      return new Fraction(totalStaked).shr(18);
    },
    {
      refetchInterval: RefetchInterval.Normal,
    },
  );
};

export const useVaultPendingRewards = () => {
  const sdk = getSDK();
  const account = currentWallet.useAccount();
  return useQuery(
    ['useVaultPendingRewards', account],
    async () => {
      const rewards = await sdk.getAmplyVault().pendingRewards(account!);
      return new Fraction(rewards).shr(18);
    },
    {
      enabled: !!account,
      refetchInterval: RefetchInterval.Normal,
    },
  );
};

export const useVaultDeposit = () => {
  const account = useAccount();
  const provider = useProvider();
  const connectorProvider = getConnector().provider;
  const chainId = useChainId();
  const [gasTokenSymbol, gasTokenAddress] = usePaymaster((s) => [
    s.token,
    s.tokenAddress,
  ]);
  return useMutation(
    async ({ poolId, amount }: { poolId?: number; amount?: Fraction }) => {
      if (
        !chainId ||
        !isNumber(poolId) ||
        !amount ||
        !provider ||
        !account ||
        !connectorProvider
      )
        return;

      const sdk = getSDK();
      const amplyVaultAddress = sdk.getContractAddress('AmplyVault');
      const assetAddress = getContractAddress(chainId, 'Amply');
      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const token = sdk.getERC20(assetAddress, browserSigner);
      const decimals = await token.decimals();

      await approveIfNeeded({
        account,
        spender: amplyVaultAddress,
        amount: amount.shl(Number(decimals)).quotient,
        token,
        gasTokenAddress,
        gasTokenSymbol,
      });

      const amplyVault = sdk.getAmplyVault(browserSigner);
      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const gasLimit = await amplyVault.deposit.estimateGas(
          poolId,
          amount.shl(Number(decimals)).quotient,
        );
        const functionData = amplyVault.interface.encodeFunctionData(
          'deposit',
          [poolId, amount.shl(Number(decimals)).quotient],
        );
        const data = await getZyfiData({
          gasLimit,
          fromAddress: account,
          toAddress: await amplyVault.getAddress(),
          gasTokenAddress,
          functionData,
        });
        const tx = await browserSigner.sendTransaction(data.txData);
        return await tx.wait();
      }

      const tx = await amplyVault.deposit(
        poolId,
        amount.shl(Number(decimals)).quotient,
      );

      return await tx.wait();
    },
  );
};

export const useVaultClaimRewards = () => {
  const account = useAccount();
  const provider = useProvider();
  const connectorProvider = getConnector().provider;
  const chainId = useChainId();
  const [gasTokenSymbol, gasTokenAddress] = usePaymaster((s) => [
    s.token,
    s.tokenAddress,
  ]);
  return useMutation(async () => {
    if (!chainId || !provider || !account || !connectorProvider) return;
    const sdk = getSDK();
    const browserSigner = await new BrowserProvider(
      connectorProvider,
    ).getSigner();

    const amplyVault = sdk.getAmplyVault(browserSigner);
    if (gasTokenSymbol !== tokenMap.zkCRO) {
      const gasLimit = await amplyVault.deposit.estimateGas(0, 0);
      const functionData = amplyVault.interface.encodeFunctionData(
        'deposit',
        [0, 0],
      );
      const data = await getZyfiData({
        gasLimit,
        fromAddress: account,
        toAddress: await amplyVault.getAddress(),
        gasTokenAddress,
        functionData,
      });
      const tx = await browserSigner.sendTransaction(data.txData);
      return await tx.wait();
    }

    const tx = await amplyVault.deposit(0, 0);
    return await tx.wait();
  });
};

export const useVaultWithdraw = () => {
  const account = useAccount();
  const provider = useProvider();
  const connectorProvider = getConnector().provider;
  const chainId = useChainId();
  const [gasTokenSymbol, gasTokenAddress] = usePaymaster((s) => [
    s.token,
    s.tokenAddress,
  ]);
  return useMutation(async ({ stakeId }: { stakeId: number }) => {
    if (
      !chainId ||
      !isNumber(stakeId) ||
      !provider ||
      !account ||
      !connectorProvider
    )
      return;
    const sdk = getSDK();
    const browserSigner = await new BrowserProvider(
      connectorProvider,
    ).getSigner();
    const amplyVault = sdk.getAmplyVault(browserSigner);
    if (gasTokenSymbol !== tokenMap.zkCRO) {
      const gasLimit = await sdk
        .getAmplyVault(browserSigner)
        .withdraw.estimateGas(stakeId);
      const functionData = amplyVault.interface.encodeFunctionData('withdraw', [
        stakeId,
      ]);
      const data = await getZyfiData({
        gasLimit,
        fromAddress: account,
        toAddress: await amplyVault.getAddress(),
        gasTokenAddress,
        functionData,
      });
      const tx = await browserSigner.sendTransaction(data.txData);
      return await tx.wait();
    }

    const tx = await amplyVault.withdraw(stakeId);

    return await tx.wait();
  });
};

export const useVaultBatchUpgrade = () => {
  const account = useAccount();
  const provider = useProvider();
  const connectorProvider = getConnector().provider;
  const chainId = useChainId();
  const [gasTokenSymbol, gasTokenAddress] = usePaymaster((s) => [
    s.token,
    s.tokenAddress,
  ]);

  return useMutation(
    async ({
      stakeIds,
      newPids,
    }: {
      stakeIds: number[];
      newPids: number[];
    }) => {
      if (!chainId || !provider || !account || !connectorProvider) return;
      const sdk = getSDK();
      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();

      const amplyVault = sdk.getAmplyVault(browserSigner);
      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const gasLimit = await sdk
          .getAmplyVault(browserSigner)
          .batchUpgrade.estimateGas(stakeIds, newPids);
        const functionData = amplyVault.interface.encodeFunctionData(
          'batchUpgrade',
          [stakeIds, newPids],
        );
        const data = await getZyfiData({
          gasLimit,
          fromAddress: account,
          toAddress: await amplyVault.getAddress(),
          gasTokenAddress,
          functionData,
        });
        const tx = await browserSigner.sendTransaction(data.txData);
        return await tx.wait();
      }

      const tx = await amplyVault.batchUpgrade(stakeIds, newPids);

      return await tx.wait();
    },
  );
};
