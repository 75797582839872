import { Center, Link } from '@chakra-ui/react';
import type {
  ModalController,
  TxStatusController,
} from '@ui/commonStateController';
import {
  createModalController,
  createTxStatusController,
  TxStatus,
} from '@ui/commonStateController';
import { ModalOrDrawerBody } from '@ui/components/ModalOrDrawer';
import { getExplorerLink } from '@ui/utils/getExplorerLink';
import { create } from 'zustand';

import { DefaultContent } from './DefaultContent';
import ModalLayout from './ModalLayout';

export const useClaimModal = create<
  ModalController & TxStatusController & { isPreAmpCompleted: boolean }
>()((...a) => ({
  ...createModalController(...a),
  ...createTxStatusController(...a),
  isPreAmpCompleted: false,
}));

const ClaimModal = () => {
  const [isOpen, txStatus, txHash, txError, closeModal] = useClaimModal((s) => [
    s.isOpen,
    s.txStatus,
    s.txHash,
    s.txError,
    s.close,
  ]);

  return (
    <ModalLayout
      isOpen={isOpen}
      closeModal={closeModal}
      txStatus={txStatus}
      txError={txError}
    >
      {txStatus === TxStatus.Default && <DefaultContent />}
      {txStatus === TxStatus.Success ? (
        <>
          <ModalOrDrawerBody>
            <Center textStyle={'h3'}>Transaction complete</Center>
            <Center color={'primary.text2'} my={2}>
              You have claimed
            </Center>
            <Center mt={4}>
              <Link
                href={getExplorerLink(txHash ?? '0x000000', 'transaction')}
                isExternal
                color="primary.cta"
                target="_blank"
                textStyle={'cta'}
              >
                View on CronosZK Explorer
              </Link>
            </Center>
          </ModalOrDrawerBody>
        </>
      ) : null}
    </ModalLayout>
  );
};

export default ClaimModal;
