import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getSDK, getZyfiData } from '@ui/utils';
import { BrowserProvider } from 'zksync-ethers';

import { useAllPoolsData } from './useAllPoolsData';
import { useYieldRefund } from './useYieldRefund';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useClaimAllYieldRefundToSelf = () => {
  const refetchYieldRefund = useYieldRefund().refetch;
  const account = useAccount();
  const provider = useProvider();
  const chainId = useChainId();
  const connectorProvider = getConnector().provider;
  const { data: allPoolsData } = useAllPoolsData();
  const gasTokenSymbol = usePaymaster((s) => s.token);
  const gasTokenAddress =
    allPoolsData?.find((token) => token.symbol === gasTokenSymbol)
      ?.tokenAddress ?? '';

  return useMutation(
    async ({
      vUSDRefund = false,
      vETHRefund = false,
    }: {
      vUSDRefund: boolean | undefined;
      vETHRefund: boolean | undefined;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;
      const sdk = getSDK();
      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const results = [];

      if (vUSDRefund) {
        if (gasTokenSymbol !== tokenMap.zkCRO) {
          const getExtraYieldDistributeATokenvUSD =
            sdk.getExtraYieldDistributeATokenvUSD(browserSigner);
          const gasLimit =
            await getExtraYieldDistributeATokenvUSD.claim.estimateGas();
          const functionData =
            getExtraYieldDistributeATokenvUSD.interface.encodeFunctionData(
              'claim',
            );
          const data = await getZyfiData({
            gasLimit,
            fromAddress: account,
            toAddress: await getExtraYieldDistributeATokenvUSD.getAddress(),
            gasTokenAddress,
            functionData,
          });
          const tx = await browserSigner.sendTransaction(data.txData);
          const res = await tx.wait();
          results.push(res);
        } else {
          const vUSDRefundTx = await sdk
            .getExtraYieldDistributeATokenvUSD(browserSigner)
            .claim();
          const res = await vUSDRefundTx.wait();
          results.push(res);
        }
      }

      if (vETHRefund) {
        if (gasTokenSymbol !== tokenMap.zkCRO) {
          const getExtraYieldDistributeATokenvETH =
            sdk.getExtraYieldDistributeATokenvETH(browserSigner);
          const gasLimit =
            await getExtraYieldDistributeATokenvETH.claim.estimateGas();
          const functionData =
            getExtraYieldDistributeATokenvETH.interface.encodeFunctionData(
              'claim',
            );
          const data = await getZyfiData({
            gasLimit,
            fromAddress: account,
            toAddress: await getExtraYieldDistributeATokenvETH.getAddress(),
            gasTokenAddress,
            functionData,
          });
          const tx = await browserSigner.sendTransaction(data.txData);
          const res = await tx.wait();
          results.push(res);
        } else {
          const vETHRefundTx = await sdk
            .getExtraYieldDistributeATokenvETH(browserSigner)
            .claim();
          const res = await vETHRefundTx.wait();
          results.push(res);
        }
      }
      return results;
    },
    {
      onSuccess: () => refetchYieldRefund(),
    },
  );
};
