import type { ContractAddressMap } from '@amply-app/sdk';
import { getContractAddress } from '@amply-app/sdk';
import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getSDK, getZyfiData } from '@ui/utils';
import { BrowserProvider } from 'zksync-ethers';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useSetUserUseReserveAsCollateral = () => {
  const account = useAccount();
  const provider = useProvider();
  const chainId = useChainId();
  const connectorProvider = getConnector().provider;
  const [gasTokenSymbol, gasTokenAddress] = usePaymaster((s) => [
    s.token,
    s.tokenAddress,
  ]);
  return useMutation(
    async ({
      tokenSymbol,
      asCollateral,
    }: {
      tokenSymbol: keyof ContractAddressMap | 'zkCRO';
      asCollateral: boolean;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const sdk = getSDK();

      const assetAddress = getContractAddress(
        chainId,
        tokenSymbol === 'zkCRO' ? 'wzkCRO' : tokenSymbol,
      );
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const l2Pool = sdk.getL2Pool(poolAddress, browserSigner);
        const gasLimit = await l2Pool[
          'setUserUseReserveAsCollateral(address,bool)'
        ].estimateGas(assetAddress, asCollateral);
        const functionData = l2Pool.interface.encodeFunctionData(
          'setUserUseReserveAsCollateral(address,bool)',
          [assetAddress, asCollateral],
        );
        const data = await getZyfiData({
          gasLimit,
          fromAddress: account,
          toAddress: await l2Pool.getAddress(),
          gasTokenAddress,
          functionData,
        });
        const tx = await browserSigner.sendTransaction(data.txData);
        return await tx.wait();
      }

      const tx = await sdk
        .getL2Pool(poolAddress, browserSigner)
        ['setUserUseReserveAsCollateral(address,bool)'](
          assetAddress,
          asCollateral,
        );

      return await tx.wait();
    },
  );
};
