import { Flex, Stack, Switch, Text } from '@chakra-ui/react';
import HealthFactor from '@ui/components/HealthFactor';
import { Num, Percent } from '@ui/components/Num';
import { PercentageSelect } from '@ui/components/PercentageSelect';
import { Info } from '@ui/components/TextBlocks';
import { TokenInput } from '@ui/components/TokenInput';
import { useBorrowAPY, useTokenPrice } from '@ui/hooks/data';
import {
  HealthFactorType,
  useHealthFactor,
} from '@ui/hooks/data/useHealthFactor';
import { useUserAccountData } from '@ui/hooks/data/useUserAccountData';
import { useValidationBorrow } from '@ui/hooks/validation';
import { Fraction } from 'bi-fraction';

import { useSupplyAndBorrowModal } from '..';

export const Borrow = () => {
  const [
    borrowAmount,
    value,
    setSelectedTokenData,
    tokenData,
    setValue,
    isMaxAmount,
    setMaxAmount,
    isBorrowRiskAgreed,
    setIsBorrowRiskAgreed,
    canSelectTokens,
  ] = useSupplyAndBorrowModal((s) => [
    s.borrowAmount,
    s.borrowAmountStr,
    s.setBorrowTokenData,
    s.borrowTokenData,
    s.setBorrowAmountStr,
    s.isMaxAmount,
    s.setMaxAmount,
    s.isBorrowRiskAgreed,
    s.setIsBorrowRiskAgreed,
    s.canSelectTokens,
  ]);

  const { data: borrowAPY } = useBorrowAPY(tokenData?.tokenAddress);
  const { data: tokenPrice } = useTokenPrice(tokenData?.tokenAddress);
  const { data: userAccountData } = useUserAccountData();
  const newHealthFactor = useHealthFactor(
    HealthFactorType.Borrow,
    tokenPrice ? borrowAmount?.mul(tokenPrice) : Fraction.ZERO,
  );

  const errorMsg = useValidationBorrow(
    borrowAmount ?? Fraction.ZERO,
    tokenData,
  );
  const maxBorrowableAmount =
    userAccountData?.availableBorrowsBase.div(tokenPrice ?? Fraction.ONE) ??
    Fraction.ZERO;
  return (
    <>
      <Stack spacing={3}>
        <TokenInput
          errorMsg={errorMsg}
          warningMsg={
            newHealthFactor.gte(Fraction.ONE) &&
            newHealthFactor.lte(new Fraction('3'))
              ? 'We recommended you increase your Health Factor by borrowing less or supplying more'
              : undefined
          }
          {...(canSelectTokens && {
            setSelectedTokenData: (tokenData) => {
              setSelectedTokenData(tokenData);
              setMaxAmount(false);
            },
          })}
          value={value}
          selectedTokenData={tokenData}
          onChange={(value) => {
            setValue(value);
            setMaxAmount(new Fraction(value).eq(maxBorrowableAmount));
          }}
          topLabel={
            <Num
              value={userAccountData?.availableBorrowsBase}
              textStyle={'body1'}
              suffix={'USD'}
              prefix={'Max borrowable amount: '}
              mb={1}
              decimalPlaces={4}
            />
          }
        />
        <PercentageSelect
          value={value}
          maxValue={maxBorrowableAmount}
          decimalPlaces={4}
          onSelect={setValue}
          isDisabled={!tokenData}
          setMaxAmount={setMaxAmount}
        />
        <Info
          title="Borrow APR"
          content={tokenData ? <Percent value={borrowAPY} /> : <Text>-</Text>}
        />
        {isMaxAmount && (
          <Flex my={3} gap={2} justifyContent={'space-between'}>
            <Text>
              I acknowledge that borrowing this amount will put me at high risk
              of liquidation
            </Text>
            <Switch
              isChecked={isBorrowRiskAgreed}
              onChange={() => setIsBorrowRiskAgreed(!isBorrowRiskAgreed)}
            />
          </Flex>
        )}
        <HealthFactor value={newHealthFactor} />
      </Stack>
    </>
  );
};
