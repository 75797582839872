import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getSDK, getZyfiData } from '@ui/utils';
import { BrowserProvider } from 'zksync-ethers';

import { useUserReservesIncentivesData } from './useUserReservesIncentivesData';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useClaimAllRewardsToSelf = () => {
  const refetchUserReservesIncentivesData =
    useUserReservesIncentivesData().refetch;
  const account = useAccount();
  const provider = useProvider();
  const chainId = useChainId();
  const connectorProvider = getConnector().provider;
  const [gasTokenSymbol, gasTokenAddress] = usePaymaster((s) => [
    s.token,
    s.tokenAddress,
  ]);

  return useMutation(
    async ({ tokenAddresses }: { tokenAddresses: string[] }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const sdk = getSDK();
      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();

      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const rewardsController = sdk.getRewardsController(browserSigner);
        const gasLimit =
          await rewardsController.claimAllRewardsToSelf.estimateGas(
            tokenAddresses,
          );
        const functionData = rewardsController.interface.encodeFunctionData(
          'claimAllRewardsToSelf',
          [tokenAddresses],
        );
        const data = await getZyfiData({
          gasLimit,
          fromAddress: account,
          toAddress: await rewardsController.getAddress(),
          gasTokenAddress,
          functionData,
        });
        const tx = await browserSigner.sendTransaction(data.txData);
        return await tx.wait();
      }

      const tx = await sdk
        .getRewardsController(browserSigner)
        .claimAllRewardsToSelf(tokenAddresses);

      return await tx.wait();
    },
    {
      onSuccess: () => refetchUserReservesIncentivesData(),
    },
  );
};
