import { getContractAddress } from '@amply-app/sdk';
import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getSDK, getZyfiData } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { MaxInt256 } from 'ethers';
import { BrowserProvider } from 'zksync-ethers';

import { INTEREST_RATE_MODE, REFERRAL_CODE } from '../data/constants';
import { type UseAllPoolsData } from '../data/useAllPoolsData';
import { checkZKCROAddress } from '../data/utils';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useBorrowMutation = () => {
  const account = useAccount();
  const chainId = useChainId();
  const provider = useProvider();
  const connectorProvider = getConnector().provider;
  const [gasTokenSymbol, gasTokenAddress] = usePaymaster((s) => [
    s.token,
    s.tokenAddress,
  ]);
  return useMutation(
    async ({
      amount,
      tokenData,
    }: {
      amount: Fraction;
      tokenData: UseAllPoolsData;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const sdk = getSDK();
      const tokenAddress = checkZKCROAddress(tokenData.tokenAddress);
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      const token = sdk.getERC20(tokenAddress!, browserSigner);
      const decimals = await token.decimals();

      if (gasTokenSymbol !== tokenMap.zkCRO) {
        if (tokenData.symbol === tokenMap.zkCRO) {
          const gatewayAddress = getContractAddress(
            chainId,
            'WrappedTokenGatewayV3',
          );
          const wzkCROVarDebtToken = tokenData.variableDebtToken;

          const borrowAllowance = await sdk
            .getVariableDebtToken(wzkCROVarDebtToken, browserSigner)
            .borrowAllowance(account, gatewayAddress);

          if (new Fraction(borrowAllowance).lt(amount)) {
            const variableDebtToken = sdk.getVariableDebtToken(
              wzkCROVarDebtToken,
              browserSigner,
            );

            const approvalGasLimit =
              await variableDebtToken.approveDelegation.estimateGas(
                gatewayAddress,
                MaxInt256,
              );
            const functionData = variableDebtToken.interface.encodeFunctionData(
              'approveDelegation',
              [gatewayAddress, MaxInt256],
            );
            const data = await getZyfiData({
              gasLimit: approvalGasLimit,
              fromAddress: account,
              toAddress: await variableDebtToken.getAddress(),
              gasTokenAddress,
              functionData,
            });
            const approvalTx = await browserSigner.sendTransaction(data.txData);
            await approvalTx.wait();
          }
          const wrappedTokenGateway =
            sdk.getWrappedTokenGatewayV3(browserSigner);
          const gasLimit = await wrappedTokenGateway.borrowETH.estimateGas(
            poolAddress,
            amount.shl(Number(decimals)).quotient,
            INTEREST_RATE_MODE,
            REFERRAL_CODE,
          );
          const functionData = wrappedTokenGateway.interface.encodeFunctionData(
            'borrowETH',
            [
              poolAddress,
              amount.shl(Number(decimals)).quotient,
              INTEREST_RATE_MODE,
              REFERRAL_CODE,
            ],
          );
          const data = await getZyfiData({
            gasLimit,
            fromAddress: account,
            toAddress: await wrappedTokenGateway.getAddress(),
            gasTokenAddress,
            functionData,
          });
          const tx = await browserSigner.sendTransaction(data.txData);
          return await tx.wait();
        } else {
          const l2Pool = sdk.getL2Pool(poolAddress, browserSigner);
          const gasLimit = await l2Pool[
            'borrow(address,uint256,uint256,uint16,address)'
          ].estimateGas(
            tokenAddress!,
            amount.shl(Number(decimals)).quotient,
            INTEREST_RATE_MODE,
            REFERRAL_CODE,
            account,
          );
          const functionData = l2Pool.interface.encodeFunctionData(
            'borrow(address,uint256,uint256,uint16,address)',
            [
              tokenAddress!,
              amount.shl(Number(decimals)).quotient,
              INTEREST_RATE_MODE,
              REFERRAL_CODE,
              account,
            ],
          );
          const data = await getZyfiData({
            gasLimit,
            fromAddress: account,
            toAddress: await l2Pool.getAddress(),
            gasTokenAddress,
            functionData,
          });
          const tx = await browserSigner.sendTransaction(data.txData);
          return await tx.wait();
        }
      }

      let tx;

      if (tokenData.symbol === tokenMap.zkCRO) {
        // if gasTokenSymbol is not zkCRO, it has been approved when obtaining the gas limit
        if (gasTokenSymbol === tokenMap.zkCRO) {
          // Borrow via gateway needs approveDelegation
          const gatewayAddress = getContractAddress(
            chainId,
            'WrappedTokenGatewayV3',
          );
          const wzkCROVarDebtToken = tokenData.variableDebtToken;

          const borrowAllowance = await sdk
            .getVariableDebtToken(wzkCROVarDebtToken, browserSigner)
            .borrowAllowance(account, gatewayAddress);

          if (new Fraction(borrowAllowance).lt(amount)) {
            const approvalTx = await sdk
              .getVariableDebtToken(wzkCROVarDebtToken, browserSigner)
              .approveDelegation(gatewayAddress, MaxInt256);
            await approvalTx.wait();
          }
        }

        tx = await sdk
          .getWrappedTokenGatewayV3(browserSigner)
          .borrowETH(
            poolAddress,
            amount.shl(Number(decimals)).quotient,
            INTEREST_RATE_MODE,
            REFERRAL_CODE,
          );
      } else {
        tx = await sdk
          .getL2Pool(poolAddress, browserSigner)
          ['borrow(address,uint256,uint256,uint16,address)'](
            tokenAddress!,
            amount.shl(Number(decimals)).quotient,
            INTEREST_RATE_MODE,
            REFERRAL_CODE,
            account,
          );
      }
      return await tx.wait();
    },
  );
};
