import { Button, ModalCloseButton } from '@chakra-ui/react';
import { TxStatus } from '@ui/commonStateController';
import type { EthersError } from '@ui/components/CommonContents';
import {
  TransactionErrorContent,
  TransactionLoadingContent,
} from '@ui/components/CommonContents';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';

interface ModalLayout {
  isOpen: boolean;
  closeModal: () => void;
  txStatus: TxStatus;
  txError: EthersError | null | undefined;
  children?: React.ReactNode;
}

const ModalLayout = ({
  isOpen,
  closeModal,
  txStatus,
  txError,
  children,
}: ModalLayout) => {
  return (
    <ModalOrDrawer
      scrollBehavior="inside"
      placement="bottom"
      isOpen={isOpen}
      onClose={closeModal}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent
        bgColor={'primary.background'}
        backgroundImage={'url(/images/bg-full.svg)'}
        backgroundSize={'cover'}
        roundedTop="10px"
        minH={'60vh'}
        minW={{ desktop: '500px' }}
      >
        <>
          <ModalOrDrawerHeader>Claim</ModalOrDrawerHeader>
          <InDesktop>
            <ModalCloseButton color={'primary.cta'} />
          </InDesktop>
          {txStatus === TxStatus.Loading && <TransactionLoadingContent />}
          {txStatus === TxStatus.Failed && (
            <TransactionErrorContent error={txError} />
          )}
          {children}
          <ModalOrDrawerFooter>
            {txStatus === TxStatus.Success && (
              <Button w={'100%'} onClick={closeModal}>
                Done
              </Button>
            )}
          </ModalOrDrawerFooter>
        </>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

export default ModalLayout;
