import { TxStatus } from '@ui/commonStateController';
import { useClaimAllYieldRefundToSelf } from '@ui/hooks/data/useClaimAllYieldRefundToSelf';
import type { EthersError } from 'ethers';
import { useCallback } from 'react';

import { useYieldRefundModal } from '../YieldRefundModal';

export const useClaimYieldRefund = (
  vUSDRefund: boolean | undefined,
  vETHRefund: boolean | undefined,
) => {
  const mutation = useClaimAllYieldRefundToSelf();

  const [
    setTxStatus,
    setvUSDYieldRefundTxHash,
    setvETHYieldRefundTxHash,
    setTxError,
  ] = useYieldRefundModal((s) => [
    s.setTxStatus,
    s.setvUSDYieldRefundTxHash,
    s.setvETHYieldRefundTxHash,
    s.setTxError,
  ]);
  return useCallback(async () => {
    try {
      if (!vUSDRefund && !vETHRefund) return;
      setTxStatus(TxStatus.Loading);
      const results = await mutation.mutateAsync({
        vUSDRefund: vUSDRefund,
        vETHRefund: vETHRefund,
      });
      if (results) {
        if (vUSDRefund && vETHRefund) {
          setvUSDYieldRefundTxHash(results[0]?.hash);
          setvETHYieldRefundTxHash(results[1]?.hash);
        } else if (vUSDRefund) {
          setvUSDYieldRefundTxHash(results[0]?.hash);
        } else if (vETHRefund) {
          setvETHYieldRefundTxHash(results[0]?.hash);
        }
      }
      setTxStatus(TxStatus.Success);
    } catch (e: unknown) {
      setTxStatus(TxStatus.Failed);
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setTxError(e as EthersError);
    }
  }, [
    setTxStatus,
    mutation,
    vUSDRefund,
    vETHRefund,
    setvUSDYieldRefundTxHash,
    setvETHYieldRefundTxHash,
    setTxError,
  ]);
};
