import { getContractAddress } from '@amply-app/sdk';
import { currentWallet } from '@amply-app/wallet';
import { useQuery } from '@tanstack/react-query';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { getChainId } from '@ui/utils/getChainId';
import { Fraction } from 'bi-fraction';

import { useTokenPrices } from './useUsdPrices';

export const useYieldRefund = () => {
  const sdk = getSDK();
  const account = currentWallet.useAccount();
  const vUSDContractAddress = getContractAddress(
    getChainId(),
    'vUSD',
  ).toLowerCase();
  const vETHContractAddress = getContractAddress(
    getChainId(),
    'vETH',
  ).toLowerCase();

  const { data: prices } = useTokenPrices([
    vUSDContractAddress,
    vETHContractAddress,
  ]);

  return useQuery(
    ['usePendingYield', account, prices],
    async () => {
      const vUSDInUSDPrice = prices?.[0] ?? 0;
      const vETHInUSDPrice = prices?.[1] ?? 0;

      const vUSDYieldRefundAmount = new Fraction(
        await sdk
          .getExtraYieldDistributeATokenvUSD()
          .getPendingYield.staticCall(account!),
      ).shr(18);

      const vETHYieldRefundAmount = new Fraction(
        await sdk
          .getExtraYieldDistributeATokenvETH()
          .getPendingYield.staticCall(account!),
      ).shr(18);

      return {
        vUSDYieldRefundAmount,
        vETHYieldRefundAmount,
        vUSDInUSDPrice,
        vETHInUSDPrice,
      };
    },
    { enabled: !!account, refetchInterval: RefetchInterval.Normal },
  );
};
