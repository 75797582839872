import { Fraction } from 'bi-fraction';

import { getChainId } from './getChainId';

const MIN_ZYFI_GAS_LIMIT = 150000;
interface ZyfiResponse {
  txData: {
    chainId: number;
    from: string;
    to: string;
    data: string;
    value: string;
    customData: {
      paymasterParams: {
        paymaster: string;
        paymasterInput: string;
      };
      gasPerPubdata: number;
    };
    maxFeePerGas: string;
    gasLimit: number;
  };
  gasLimit: string;
  gasPrice: string;
  tokenAddress: string;
  tokenPrice: string;
  feeTokenAmount: string;
  feeTokendecimals: string;
  feeUSD: string;
  estimatedFinalFeeTokenAmount: string;
  estimatedFinalFeeUSD: string;
}

const getNewGaslimit = ({ gasLimit }: { gasLimit: bigint }) => {
  const scaledGasLimit = new Fraction(gasLimit)
    .add(new Fraction(60000))
    .mul(new Fraction(11000))
    .div(new Fraction(10000));
  const newGasLimit = BigInt(
    scaledGasLimit.numerator / scaledGasLimit.denominator,
  );
  return newGasLimit;
};

export const getZyfiData = async ({
  gasLimit,
  fromAddress,
  toAddress,
  functionData,
  gasTokenAddress,
  value,
}: {
  gasLimit: bigint;
  fromAddress: string;
  toAddress: string;
  functionData: string;
  gasTokenAddress: string;
  value?: bigint;
}): Promise<ZyfiResponse> => {
  const chainId = getChainId();
  const payload = {
    chainId,
    gasLimit: Math.max(
      Number(getNewGaslimit({ gasLimit })),
      MIN_ZYFI_GAS_LIMIT,
    ),
    txData: {
      from: fromAddress,
      to: toAddress,
      data: functionData,
      value: value?.toString(),
    },
    feeTokenAddress: gasTokenAddress,
  };

  const response = await fetch('https://api.zyfi.org/api/erc20_paymaster/v1', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return await response.json();
};
