import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import {
  ModalOrDrawerBody,
  ModalOrDrawerFooter,
} from '@ui/components/ModalOrDrawer';
import { Num } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { Paymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { useYieldRefund } from '@ui/hooks/data/useYieldRefund';
import React, { useEffect } from 'react';

import { useClaimYieldRefund } from '../hooks/useClaimYieldRefund';
import { useYieldRefundModal } from './index';

export const DefaultContent: React.FC = () => {
  const { data: yieldRefund } = useYieldRefund();
  const [setvUSDYieldRefundAmount, setvETHYieldRefundAmount] =
    useYieldRefundModal((s) => [
      s.setvUSDYieldRefundAmount,
      s.setvETHYieldRefundAmount,
    ]);

  useEffect(() => {
    if (yieldRefund?.vUSDYieldRefundAmount) {
      setvUSDYieldRefundAmount(yieldRefund.vUSDYieldRefundAmount);
    }
    if (yieldRefund?.vETHYieldRefundAmount) {
      setvETHYieldRefundAmount(yieldRefund.vETHYieldRefundAmount);
    }
  }, [yieldRefund, setvUSDYieldRefundAmount, setvETHYieldRefundAmount]);

  const claim = useClaimYieldRefund(
    yieldRefund?.vUSDYieldRefundAmount.gt(0),
    yieldRefund?.vETHYieldRefundAmount.gt(0),
  );

  const handleConfirm = () => {
    claim();
  };

  return (
    <>
      <ModalOrDrawerBody>
        <Flex direction={'column'} gap={'2'}>
          <Flex justifyContent="space-between" py={1}>
            <Text fontSize={'body3'}>vUSD</Text>
            <Flex flexDirection={'column'} alignItems="flex-end">
              <Flex gap={1}>
                <Num
                  textStyle={'body1Bold'}
                  value={yieldRefund?.vUSDYieldRefundAmount}
                  decimalPlaces={4}
                />
                <TokenImage w="24px" symbol={'vUSD'} />
              </Flex>
              <UsdValue
                value={yieldRefund?.vUSDYieldRefundAmount}
                price={yieldRefund?.vUSDInUSDPrice}
                textStyle={'body3'}
                color={'primary.text2'}
                decimalPlaces={4}
              />
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" py={1}>
            <Text fontSize={'body3'}>vETH</Text>
            <Flex flexDirection={'column'} alignItems="flex-end">
              <Flex gap={1}>
                <Num
                  textStyle={'body1Bold'}
                  value={yieldRefund?.vETHYieldRefundAmount}
                  decimalPlaces={4}
                />
                <TokenImage w="24px" symbol={'vETH'} />
              </Flex>
              <UsdValue
                value={yieldRefund?.vETHYieldRefundAmount}
                price={yieldRefund?.vETHInUSDPrice}
                textStyle={'body3'}
                color={'primary.text2'}
                decimalPlaces={4}
              />
            </Flex>
          </Flex>
        </Flex>
      </ModalOrDrawerBody>
      <ModalOrDrawerFooter>
        <Stack w={'full'} spacing={4}>
          <Paymaster />
          <Button
            isDisabled={
              yieldRefund?.vETHYieldRefundAmount.lte(0) &&
              yieldRefund.vUSDYieldRefundAmount.lte(0)
            }
            onClick={handleConfirm}
          >
            {'Confirm'}
          </Button>
        </Stack>
      </ModalOrDrawerFooter>
    </>
  );
};
