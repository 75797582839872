import { Center, Link, Text, VStack } from '@chakra-ui/react';
import type {
  ModalController,
  TxStatusController,
} from '@ui/commonStateController';
import {
  createModalController,
  createTxStatusController,
  TxStatus,
} from '@ui/commonStateController';
import { ModalOrDrawerBody } from '@ui/components/ModalOrDrawer';
import { getExplorerLink } from '@ui/utils/getExplorerLink';
import { create } from 'zustand';

import ModalLayout from '../ClaimModal/ModalLayout';
import type { YieldRefundTxStatusController } from './createYieldRefundTxStatusController';
import { createYieldRefundTxStatusController } from './createYieldRefundTxStatusController';
import { DefaultContent } from './DefaultContent';

export const useYieldRefundModal = create<
  ModalController & TxStatusController & YieldRefundTxStatusController
>()((...a) => ({
  ...createModalController(...a),
  ...createTxStatusController(...a),
  ...createYieldRefundTxStatusController(...a),
}));

const YieldRefundModal = () => {
  const [
    isOpen,
    txStatus,
    vUSDYieldRefundTxHash,
    vETHYieldRefundTxHash,
    txError,
    closeModal,
    vUSDYieldRefundAmount,
    vETHYieldRefundAmount,
  ] = useYieldRefundModal((s) => [
    s.isOpen,
    s.txStatus,
    s.vUSDYieldRefundTxHash,
    s.vETHYieldRefundTxHash,
    s.txError,
    s.close,
    s.vUSDYieldRefundAmount,
    s.vETHYieldRefundAmount,
  ]);

  return (
    <ModalLayout
      isOpen={isOpen}
      closeModal={closeModal}
      txStatus={txStatus}
      txError={txError}
    >
      {txStatus === TxStatus.Default && <DefaultContent />}
      {txStatus === TxStatus.Success && (
        <>
          <ModalOrDrawerBody>
            <Center textStyle={'h3'}>Transaction complete</Center>
            <VStack spacing={4} mt={2}>
              {vUSDYieldRefundTxHash && (
                <VStack spacing={1}>
                  <Text color={'primary.text2'}>
                    {`You claimed ${vUSDYieldRefundAmount?.toFormat({
                      decimalPlaces: 4,
                      trailingZeros: false,
                      format: {
                        groupSeparator: ',',
                      },
                    })} vUSD`}
                  </Text>

                  <Link
                    href={getExplorerLink(vUSDYieldRefundTxHash, 'transaction')}
                    isExternal
                    color="primary.cta"
                    target="_blank"
                    textStyle={'cta'}
                  >
                    View on CronosZK Explorer
                  </Link>
                </VStack>
              )}
              {vETHYieldRefundTxHash && (
                <VStack spacing={1}>
                  <Text color={'primary.text2'}>
                    {`You claimed ${vETHYieldRefundAmount?.toFormat({
                      decimalPlaces: 4,
                      trailingZeros: false,
                      format: {
                        groupSeparator: ',',
                      },
                    })} vETH`}
                  </Text>
                  <Link
                    href={getExplorerLink(vETHYieldRefundTxHash, 'transaction')}
                    isExternal
                    color="primary.cta"
                    target="_blank"
                    textStyle={'cta'}
                  >
                    View on CronosZK Explorer
                  </Link>
                </VStack>
              )}
            </VStack>
          </ModalOrDrawerBody>
        </>
      )}
    </ModalLayout>
  );
};

export default YieldRefundModal;
