import { ChainId } from '../types';
const ADDRESS_BY_CHAIN = {
    [ChainId.CRONOS_ZKEVM_MAINNET]: {
        CronosId: '0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5',
        PoolAddressesProviderRegistry: '0xc8AFD4de7E50f59107a87DB82b5F1Eb2F41D4F0F',
        PreAmplyToken: '0xb58c0f5dc3F1FeE5f9907C5f737b3BEcCd476e61',
        Amply: '0x66b1E42B2D5bce3D701Efa3c86Cc334d80cEEfaB',
        AmplyVault: '0x40bCbB15AaFEf55b874382AEE80BdDd68f5CA23B',
        AmplyVesting: '0xbf32061bCA0dfC511DDF0a69efD95DDcF011076F',
        PoolAddressesProvider: '0xDA59Cc2Ee08ce5F830B1190bfed34415772Ddc57',
        AmplyProtocolDataProvider: '0x47656eb2A31094b348EBF458Eccb942d471324eD',
        PoolConfigurator: '0x86EeC18090D412C24C61F6fe1fa94AD458F876e3',
        ReservesSetupHelper: '0x0aCE43a9DA33cA4c3D69D582E70D2Bd84a5a3f19',
        ACLManager: '0x6C4A080556E003Ed0628024dB814A83164E17891',
        AmplyOracle: '0xF43E78E5F1204c5cAB40Dd2825f1bee9451e267e',
        L2Encoder: '0x5F3e99759962018e4c371128D3cA32E8E5FfE66A',
        EmissionManager: '0x6806b39B7A63c4FbED56B4680f86142455Cc552B',
        RewardsController: '0x35F8fddF1d4323423D80FB3B2f3C9847c9924a2b',
        WrappedTokenGatewayV3: '0xC273d6Df2C9b7e337Eb1dDc0067cE778bb8D3955',
        WalletBalanceProvider: '0x2Ce4531CB531B655006D79cc4D3FBc447d2F5913',
        UiIncentiveDataProviderV3: '0x4BEC5cf1408fffcC2C65fe1061fd7abd22d1BEC8',
        UiPoolDataProviderV3: '0xCbD2125264e9F69437845Ea52FB9d23DD31B5fB5',
        wzkCRO: '0xC1bF55EE54E16229d9b369a5502Bfe5fC9F20b6d',
        vUSD: '0x5b91e29Ae5A71d9052620Acb813d5aC25eC7a4A2',
        vETH: '0x271602A97027ee1dd03b1E6e5dB153eB659A80b1',
        wBTC: '0xd65e5dba71231d35a5802ba83dc6cb6746c9758d',
        ETH: '0x898B3560AFFd6D955b1574D87EE09e46669c60eA',
        CRO: '0xBCaA34FF9D5BFD0d948b18Cf6Bf39a882F4a1cBD',
        USDC: '0xaa5b845F8C9c047779bEDf64829601d8B264076c',
        MOON: '0xc80b437c4c0f9c74cfb2e2d063a58ffb4f82d4f0',
        H2: '0xcaf2fd3f47e7f46e99f74be579b2cc2233f33ef8',
        Paymaster: '0xF809566a93CB882E5055A815bEbe57F8aA6ED9F9',
        ExtraYieldDistributeATokenvUSD: '0x297619e9ac736aa2238d1BFe6c78114714D86a0C',
        ExtraYieldDistributeATokenvETH: '0x33244E002F34DD2af4003D1a8829D48f5EA2B9bE',
    },
    [ChainId.CRONOS_ZKEVM_TESTNET]: {
        CronosId: '',
        PoolAddressesProviderRegistry: '0x933f09Ae847176E1ddDbF1FdAcc0eB86Cd65EA76',
        PreAmplyToken: '0x20a4256Fcc9F4EBf22E278FbB9040ae59E8d66E7',
        Amply: '0x31cb3ad8516cF2c1C32FbF837C679f46ac4Ff69E',
        AmplyVault: '0x28c91986d9763E4473218Ff4f39917655Deffec8',
        AmplyVesting: '0x93b8Ce70763d9F115C04C6A2D4833a00fbe61d12',
        PoolAddressesProvider: '0xC339BD113099ED41204002774B00CD1e63ab5711',
        AmplyProtocolDataProvider: '0xc0EDBE15368CcADc29ED4A67c8d2467f5a337ffa',
        PoolConfigurator: '0x3c5c11902046B3C53fA7511F438Bc99823DE3E2C',
        ReservesSetupHelper: '0x0C623316844e627A83787DdAE03d55Fc09C7cFc1',
        ACLManager: '0x8d6e7FD482F26D49d8e8507A8D278778D5ABABdE',
        AmplyOracle: '0x272B09F5122c4427a9FDca53eE2F4BFdC9361d06',
        L2Encoder: '0xe84d2F5e55Cd428e04D66a48Cb04e4aE3f0414Ff',
        EmissionManager: '0x7410B6DFa44f9740a130f700CFE9bb9D51f3446b',
        RewardsController: '0xC00755128dD53c917147F5539c78c98115FDE267',
        WrappedTokenGatewayV3: '0xE152cCac4ab1aDc91b8aF270788f069b3947a443',
        WalletBalanceProvider: '0xaf753198aE7Ce10b26766Bd40B53507E143e50Cc',
        UiIncentiveDataProviderV3: '0xf0Cf9aebA938aD313Ad265DA6A904d41B52f5f37',
        UiPoolDataProviderV3: '0xc6Da160aD3d9f8ef64457408eCF67cba004124AA',
        wzkCRO: '0xeD73b53197189BE3Ff978069cf30eBc28a8B5837',
        vUSD: '0x9553dA89510e33BfE65fcD71c1874FF1D6b0dD75',
        vETH: '0x16a9Df93DEc0A559CdBAC00cB9E3a1BA91Bf6906',
        wBTC: '',
        ETH: '0x11c3089837549884aa9876ab726c892ed10d263d',
        CRO: '0x08A6E713f6A9Baa9af5170729448F286e5cc6a2a',
        USDC: '0xcD22FEc4653544ac8CA6971Fbc553C9cd15fFad1',
        MOON: '',
        H2: '0x7c1dA4D7Ea2E6DAEE44052aD8764548A83b5F2bE',
        Paymaster: '0x3D874b4CEF0f36a44D5fd5FF69FE0c9F44397087',
        ExtraYieldDistributeATokenvUSD: '0x7feF2CA05f2b970A8b24EdC133584EAe4fb5153D',
        ExtraYieldDistributeATokenvETH: '0x1D8440134dE4bA86aA9266E02733F3BA09762310',
    },
};
export const getContractAddress = (chainId, contractName) => {
    return ADDRESS_BY_CHAIN[chainId][contractName];
};
export const getEnsAddress = (chainId) => {
    switch (chainId) {
        case ChainId.CRONOS_ZKEVM_MAINNET:
        case ChainId.CRONOS_ZKEVM_TESTNET:
            return getContractAddress(chainId, 'CronosId');
        default:
            throw new Error('unreachable');
    }
};
