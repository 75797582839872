import { getContractAddress } from '@amply-app/sdk';
import { getChainId } from '@ui/utils/getChainId';

export const tokenMap = {
  zkCRO: 'zkCRO',
  vETH: 'vETH',
  vUSD: 'vUSD',
  wBTC: 'WBTC',
  AMPLY: 'AMPLY',
  preAMP: 'preAMP',
  ETH: 'ETH',
  CRO: 'CRO',
  USDC: 'USDC',
  MOON: 'MOON',
  H2: 'H2',
};

export const tokenAddressMap = {
  [getContractAddress(getChainId(), 'wzkCRO').toLowerCase()]: tokenMap.zkCRO,
  [getContractAddress(getChainId(), 'vUSD').toLowerCase()]: tokenMap.vUSD,
  [getContractAddress(getChainId(), 'vETH').toLowerCase()]: tokenMap.vETH,
  [getContractAddress(getChainId(), 'wBTC').toLowerCase()]: tokenMap.wBTC,
  [getContractAddress(getChainId(), 'ETH').toLowerCase()]: tokenMap.ETH,
  [getContractAddress(getChainId(), 'CRO').toLowerCase()]: tokenMap.CRO,
  [getContractAddress(getChainId(), 'USDC').toLowerCase()]: tokenMap.USDC,
  [getContractAddress(getChainId(), 'H2').toLowerCase()]: tokenMap.H2,
  [getContractAddress(getChainId(), 'MOON').toLowerCase()]: tokenMap.MOON,
};

export const tokenPathMap = {
  [tokenMap.zkCRO]: 'zk-cro',
  [tokenMap.vETH]: 'v-eth',
  [tokenMap.vUSD]: 'v-usd',
  [tokenMap.wBTC]: 'w-btc',
  [tokenMap.ETH]: 'eth',
  [tokenMap.CRO]: 'cro',
  [tokenMap.USDC]: 'usdc',
  [tokenMap.AMPLY]: 'amply',
  [tokenMap.preAMP]: 'preAMP',
  [tokenMap.MOON]: 'moon',
  [tokenMap.H2]: 'h2',
};
