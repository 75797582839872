import type { Fraction } from 'bi-fraction';
import type { StateCreator } from 'zustand';

export interface YieldRefundTxStatusController {
  vUSDYieldRefundTxHash?: string;
  vETHYieldRefundTxHash?: string;
  setvUSDYieldRefundTxHash: (txHash?: string) => void;
  setvETHYieldRefundTxHash: (txHash?: string) => void;
  vUSDYieldRefundAmount?: Fraction;
  vETHYieldRefundAmount?: Fraction;
  setvUSDYieldRefundAmount: (vUSDYieldRefundAmount: Fraction) => void;
  setvETHYieldRefundAmount: (vETHYieldRefundAmount: Fraction) => void;
}

export const createYieldRefundTxStatusController: StateCreator<
  YieldRefundTxStatusController
> = (set) => ({
  setvUSDYieldRefundTxHash(vUSDYieldRefundTxHash) {
    set({ vUSDYieldRefundTxHash });
  },
  setvETHYieldRefundTxHash(vETHYieldRefundTxHash) {
    set({ vETHYieldRefundTxHash });
  },
  setvUSDYieldRefundAmount(vUSDYieldRefundAmount) {
    set({ vUSDYieldRefundAmount });
  },
  setvETHYieldRefundAmount(vETHYieldRefundAmount) {
    set({ vETHYieldRefundAmount });
  },
});
